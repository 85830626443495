import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useAuth0 } from '../react-auth0-wrapper';

export const Login = () => {
  const { isAuthenticated, isAdmin } = useAuth0();
  const history = useHistory();
  const [cookies] = useCookies(['lastPage']);

  useEffect(() => {
    const ensurePermissions = async () => {
      const lastPage = cookies['lastPage'];
      if (lastPage) {
        history.push(lastPage);
      } else {
        history.push('/');
      }
    };
    ensurePermissions();
  }, [isAdmin, isAuthenticated, history, cookies]);

  // Main render method
  return (<></>);
};

export default Login;
