import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export const Required = ({ children }) => {
  return <><span style={{ color: 'red' }}>*&nbsp;</span>{children}</>;
};

/*
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 * From https://www.builder.io/blog/relative-time
 */
export function getRelativeTimeString(date, lang = navigator.language) {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array representing one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units
  const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds));

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

/**
 * Labels and copy for the subheader beneath the navbar, also for the main homepage.
 * The key is the URL path, with the leading slash removed. The value is an object with
 * a path, label, and copy. The copy is displayed beneath the label in the subheader.
 */
export const labelsAndCopy = {
  admin: {
    path: '/admin',
    label: 'Administration',
    copy: 'View and manage test coordinators, proctors, students, and assessments.',
  },
  dashboard: {
    path: '/dashboard',
    label: 'Proctor',
    copy: 'Real-time monitoring of the ASLA Assessment.',
  },
  reports: {
    path: '/reports',
    label: 'Reports',
    copy: 'View Individual Student Reports (ISRs).',
  },
  login: {
    path: '/login',
    label: 'Log In',
    copy: 'Log in to access the ASLA Portal.',
  },
  home: {
    path: '/',
    label: 'Home',
    copy: 'ASLA Portal Home Page.',
  },
};

/** Optional sub-header is displayed beneath the navbar and above the main content.
 * The copy is found by stripping the leading slash off the current URL. The homepage is an exception.
 * The label is */
export const renderSubheader = () => {
  const path = window.location.pathname;
  const key = path === '/' ? 'home' : path.split('/')[1];
  if (!labelsAndCopy[key]) {
    return null;
  }
  return (
    <Container fluid className="bg-body-tertiary">
      <hr className="navbar-divider my-0 ms-0" />
      <h6 className="px-3 pt-2">
        {labelsAndCopy[key].label} &mdash; <small className="text-muted">{labelsAndCopy[key].copy}</small>
      </h6>
      <hr className="nav-divider my-0 ms-0" />
    </Container>
  );
};

/** Render an info icon with a popover tooltip */
export function renderInfoToolTip(copy, header = 'Info', placement = 'top') {
  const infoIcon = ' ℹ️';
  const anchorStyle = {
    textDecoration: 'none',
  };

  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        key={placement}
        placement={placement}
        overlay={
          <Popover id="popover-positioned-top">
            <Popover.Header as="h3">{header}</Popover.Header>
            <Popover.Body>
              {copy}
            </Popover.Body>
          </Popover>
        }
      >
        {/* eslint-disable-next-line */}
        <a href="#" style={anchorStyle}>{infoIcon}</a>
      </OverlayTrigger>
    </>
  );
}

Required.propTypes = {
  children: PropTypes.node,
};


