const jwt = require('jsonwebtoken');
/**
@return {Promise<string>} - A comma-separated list of school IDs from Auth0 user appMetadata.
The value is set in the JWT token by an action in Auth0.
Note: having more than one school ID is a legacy case, so having more than one is a warning.
 */
export async function getSchoolIds(authToken) {
    // Get the school IDs from the JWT.  Note: this additional JWT "claim" is set in Auth0 Actions
    // "Add School ID to accessToken".  We pass it along as a header to the API.
    // See Auth0 > Actions > Library > Add School ID to accessToken
    const decodedJwt = jwt.decode(authToken, { complete: true });
    const jwtCustomClaimNamespace = 'https://asledcenter.org/';
    const jwtCustomClaimName = 'school_ids';
    const schoolIds = decodedJwt.payload[jwtCustomClaimNamespace + jwtCustomClaimName];
    if (!schoolIds) {
        console.warn(`No school ID found in user's authToken.  This is likely a problem.`);
        return '';
    }
    if (schoolIds.length > 1) {
        console.warn(`User has more than one school ID in their authToken.  This is likely a problem.`);
    }
    return schoolIds ? Array.from(schoolIds).join(',') : '';
}
