import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import { useAuth0 } from '../react-auth0-wrapper';
import '../styles/navbar.css';
import helpModal from './HelpModal';
import PropTypes from 'prop-types';

const NavBar = (props) => {
  const { isAuthenticated, loginWithRedirect, logout, isAdmin } = useAuth0();
  const history = useHistory();
  const [showHelp, setShowHelp] = useState(false);


  const handleDashboardClick = () => history.push('/dashboard');
  const handleReportsClick = () => history.push('/reports');
  const handleAdminClick = () => history.push('/admin');
  const handleSupportClick = () => setShowHelp(true);
  const handleLogoutClick = () => logout({ returnTo: window.location.origin });
  const handleProctorHelpClick = () => history.push('/help/proctor');
  const handleStcHelpClick = () => history.push('/help/stc');
  const authenticatedAndNdaSigned = isAuthenticated && props.isNdaSigned;
  const authenticatedNdaAdmin = isAuthenticated && props.isNdaSigned && isAdmin;

  /***********************
   * RENDER METHODS
   ***********************/

  if (props.isHealthCheck) {
    return null;
  }

  return (
    <>
      {helpModal(showHelp, setShowHelp)}
      <Navbar expand="sm" className="bg-body-tertiary navbar-right" defaultExpanded={true} collapseOnSelect>
        <Container fluid className="p-0">
          <Navbar.Brand>
            <a href="/" id="logo">
              <img alt="ASLA Logo" className="ms-3 proctor-logo" src="/Bright_ASLA_Logo_Proctor_Assessment_Horizontal.png"></img>
            </a>
            <span id="app-name">| Portal</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" >Home</Nav.Link>
              <NavDropdown title="Administration" className="disabled" id="collapsible-nav-dropdown">
                <NavDropdown.Item
                  className={!authenticatedNdaAdmin ? 'disabled' : null}
                  onClick={handleAdminClick}>Manage Administrators & Students</NavDropdown.Item>
                <NavDropdown.Item
                  className={!authenticatedNdaAdmin ? 'disabled' : null}
                  onClick={handleReportsClick}>View Score Reports</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Proctor" id="system-navbar-proctor">
                <NavDropdown.Item
                  className={!authenticatedAndNdaSigned ? 'disabled' : null}
                  onClick={handleDashboardClick}>Assessment Monitoring</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Help" id="system-navbar-help">
                <NavDropdown.Item
                  onClick={handleStcHelpClick}>School Test Coordinator Help</NavDropdown.Item>
                <NavDropdown.Item
                  onClick={handleProctorHelpClick}>Proctor Help</NavDropdown.Item>
                <NavDropdown.Item
                  onClick={handleSupportClick}>Support</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          {!isAuthenticated && (
            <div className="me-2" onClick={() => loginWithRedirect({ redirect_uri: window.location.origin + '/login' }) }>
              <span className="h1 oi oi-account-login text-success" title="login" aria-hidden="true" />
            </div>)}
          {isAuthenticated && (
            <div className="me-2" onClick={handleLogoutClick}>
              <span className="h1 oi oi-account-logout text-warning" title="logout" aria-hidden="true" />
            </div>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;

NavBar.propTypes = {
  isHealthCheck: PropTypes.bool,
  isNdaSigned: PropTypes.bool,
};
