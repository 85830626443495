import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { FlashMessageContext } from '../context/flash-message';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';

import api from '../api';
import PropTypes from 'prop-types';

export const NDA = (props) => {
  const { getTokenSilently, user } = useAuth0();
  const flashMessageContext = useContext(FlashMessageContext);

  const [ndaUri, setNdaUri] = useState('');
  const [ndaContent, setNdaContent] = useState('');
  const [formValues, setFormValues] = useState({ agree: false });
  const isNdaSigned = props.isNdaSigned;

  useEffect(() => {
    let isMounted = true;
    const fetchNdaUri = async () => {
      api.get('/admin/nda')
        .then((result) => {
          result && result.url && isMounted && setNdaUri(result.url);
        })
        .catch((error) => flashMessageContext.showMessage(error));
    };
    if (!isNdaSigned) {
      fetchNdaUri();
    }
    return () => {
      isMounted = false;
    };
  }, [isNdaSigned, flashMessageContext]);

  useEffect(() => {
    let isMounted = true;
    const fetchNdaContent = async () => {
      fetch(ndaUri)
        .then((result) => {
          if (result) {
            return result.text();
          }
        })
        .then((data) => {
          if (data && !data.startsWith('<!DOCTYPE html>')) {
            if (isMounted) {
              setNdaContent(data);
            }
            return data;
          }
        })
        .catch((error) => {
          flashMessageContext.showMessage(`NDA response is null: ${error}`);
        });
    };
    if (!isNdaSigned) {
      fetchNdaContent();
    }
    // clean up
    return () => {
      isMounted = false;
    };
  }, [ndaUri, isNdaSigned, flashMessageContext]);

  const save = async () => {
    const agreementAcknowledged = formValues.agree;

    if (agreementAcknowledged) {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const body = {
        agree_checked: formValues.agree,
        school_id: schoolIds,
        email: user.email,
      };
      const headers = { 'X-School-Ids': schoolIds };
      api.post('/admin/ndas', body, headers)
        .then((response) => {
          if (response) {
            flashMessageContext.showMessage('NDA signed successfully!', 'success', true);
            window.location.href = '/';
          } else {
            flashMessageContext.showMessage('Failed to sign NDA. No response.');
          }
        })
        .catch((error) => {
          console.error(error);
          flashMessageContext.showMessage('Failed to sign NDA. Post failed.');
        });
    } else {
      window.location.href = '#';
      flashMessageContext.showMessage('Please accept the agreement to continue...');
    }
  };

  return (
    <>
      <Container className={'m-3 mb-6'}>
        <h1>NDA and Security Agreement</h1>
        <p>
          Please read the following non-disclosure and security agreement document
          and use the check box and button below below to accept.
        </p>
      </Container>
      <Container className='container-md'>
        <ReactMarkdown className={'bg-secondary-subtle p-5 border border-dark rounded-2'}>{ndaContent}</ReactMarkdown>
        <Form id={'nda-fields'} className={'col-sm-12 mt-4'}>
          <Row className='mb-3' key={'agree'}>
            <Col className={'col-sm-2'}></Col>
            <Col className={'col-sm-6 pt-2 bg-secondary-subtle rounded-2'}>
              <Form.Group as={Col} controlId={'form_agree'}>
                <Form.Check
                  type={'checkbox'}
                  id={'agree'}
                  checked={formValues.agree}
                  label={'I agree to the terms of the NDA and Security Agreement'}
                  onChange={(e) => {
                    setFormValues({ agree: e.target.checked });
                  }}
                />
              </Form.Group>
            </Col>
            <Form.Group as={Col} controlId='formSaveButton'>
              <Col className={'col-sm-2'}>
                <Button
                  variant='success'
                  name='saveButton'
                  size='lg'
                  onClick={save}
                  className='clickable'
                >Sign</Button>
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default NDA;

NDA.propTypes = {
  isNdaSigned: PropTypes.bool,
};
