import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Contexts
import { FlashMessageContextProvider } from './context/flash-message';
import { useAuth0 } from './react-auth0-wrapper';

import { getSchoolIds } from './components/FetchData';
import { labelsAndCopy } from './components/common';

// React Bootstrap
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

// Components
import FlashMessage from './components/FlashMessage';
import flashMessage from './components/FlashMessage';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import AdminPage from './components/AdminPage';
import HealthCheck from './components/HealthCheck';
import Reports from './components/Reports';
import NDA from './components/NDA';
import Login from './components/Login';

import api from './api';
import './App.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { HelpProctor } from './components/HelpProctor';
import { HelpSchoolTestCoordinator } from './components/HelpSchoolTestCoordinator';

// eslint-disable-next-line no-unused-vars
function App() {
  const { loading, isAuthenticated, user, getTokenSilently } = useAuth0();
  const currentPath = window.location.pathname;
  const isHealthCheck = currentPath === '/healthcheck';

  const [ndaSigned, setNdaSigned] = useState(true);
  const [ndaLoading, setNdaLoading] = useState(true);
  const [schoolData, setSchoolData] = useState(null);

  useEffect(() => {
    const checkNdaSigned = async () => {
      if (user) {
        api.get(`/admin/nda/signature?email=${encodeURIComponent(user.email)}`)
          .then((signature) => {
            if (signature && signature.user_email && user.email && signature.user_email.toLowerCase().trim() === user.email.toLowerCase().trim()) {
              setNdaSigned(true);
            } else {
              setNdaSigned(false);
            }
          })
          .catch((noSignature) => {
            setNdaLoading(false);
          });
      }
    };
    setNdaLoading(true);
    checkNdaSigned();
    setNdaLoading(false);
  }, [user, ndaSigned, ndaLoading]);

  const checkSchoolAndAssessment = async () => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    if (!schoolIds) {
      flashMessage.showMessage('No school ID found in user authToken.');
      return false;
    }
    const schoolId = schoolIds.split(',')[0];
    api.get(`/schools/${schoolId}/latest-assessment`)
      .then((response) => {
        // response is JSON, not an HTTP response body
        if (!response) {
          console.error(`School ID ${schoolId} is invalid or does not have an active assessment.`);
          return false;
        } else {
          setSchoolData(response?.school)
          return true;
        }
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  if (loading || ndaLoading) {
    return (
      <div>Loading...</div>
    );
  }

  if (isAuthenticated && !schoolData) {
    if (!checkSchoolAndAssessment()) {
      flashMessage.showMessage('No active assessment for your school and year found. Please contact ASLA support.');
    }
  }

  const renderNavBar = () => {
    return (
      <NavBar
        isNdaSigned={ndaSigned}
        isHealthCheck={isHealthCheck}
        isAuthenticated={isAuthenticated}
      />
    );
  };

  const renderFlashMessage = () => {
    return (
      <FlashMessage />
    );
  };

  const renderNDA = () => {
    return (
      <NDA
        isNdaSigned={ndaSigned}
      />
    );
  };

  // if user is authenticated but hasn't signed the NDA, return the NDA component once it has loaded
  if (isAuthenticated && !ndaSigned) {
    return (
      <FlashMessageContextProvider>
        {renderNavBar()}
        {renderFlashMessage()}
        {renderNDA()}
      </FlashMessageContextProvider>
    );
  }

  const HomeWithSchoolInfo = () => <Home schoolName={schoolData?.name} />

  return (
    <FlashMessageContextProvider>
      <BrowserRouter>
        {renderNavBar()}
        {renderFlashMessage()}
        <Switch>
          <Route path='/' exact component={HomeWithSchoolInfo} />
          <Route path='/healthcheck' exact component={HealthCheck} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/reports' component={Reports} />
          <PrivateRoute path='/admin' component={AdminPage} />
          <PrivateRoute path='/login' component={Login} />
          <Route path='/help/proctor' component={HelpProctor} />
          <Route path='/help/stc' component={HelpSchoolTestCoordinator} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </FlashMessageContextProvider>
  );
}

const Home = ({ schoolName }) => {
  const { isAuthenticated, isAdmin, isProctor } = useAuth0();

  function renderButton(label, href) {
    return (
      <>
        <Col md={4}>
          <Button
            className={'btn-block'}
            variant={'success'}
            size={'lg'}
            href={href}
          >
            {label}
          </Button>
        </Col>
      </>
    );
  }

  function renderCopy(copy) {
    return (
      <>
        <Col md={4}>{copy}</Col>
      </>
    );
  }

  function renderButtons() {
    if (isAuthenticated && isAdmin) {
      return (
        <>
          <Row className="pt-4">
            {renderButton(labelsAndCopy.admin.label, '/admin')}
            {renderButton(labelsAndCopy.dashboard.label, '/dashboard')}
            {renderButton(labelsAndCopy.reports.label, '/reports')}
          </Row>
          <Row className="pt-3">
            {renderCopy(labelsAndCopy.admin.copy)}
            {renderCopy(labelsAndCopy.dashboard.copy)}
            {renderCopy(labelsAndCopy.reports.copy)}
          </Row>
        </>
      );
    } else if (isAuthenticated && isProctor) {
      return (
        <>
          <Row className="pt-4">
            <Col md={4} />
            {renderButton(labelsAndCopy.dashboard.label, '/dashboard')}
          </Row>
          <Row className="pt-3">
            <Col md={4} />
            {renderCopy(labelsAndCopy.dashboard.copy)}
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="pt-4">
            <Col md={4} />
            {renderButton(labelsAndCopy.login.label, '/login')}
          </Row>
          <Row className="pt-3">
            <Col md={4} />
            {renderCopy(labelsAndCopy.login.copy)}
          </Row>
        </>
      );
    }
  }

  function renderWelcome() {
    let loginStatus; let loginRole;
    if (isAuthenticated) {
      loginStatus = 'logged in';
      loginRole = isAdmin ? ' as a School Test Administrator (STC)' : ' as a Proctor';
    } else {
      loginStatus = 'not logged in yet';
      loginRole = '';
    }
    return (
      <>
        <Row className='alert alert-light justify-content-center'>You are {loginStatus}{loginRole}.</Row>
      </>
    );
  }

  function renderSchool() {
    return (schoolName && <h2>{schoolName}</h2>) || null
  }

  return (
    <Container className='welcome-container'>
      <h1>Welcome to the ASLA Portal</h1>
      {renderSchool()}
      {renderWelcome()}
      {renderButtons()}
    </Container>
  );
};

export default App;
