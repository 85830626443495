import React, {useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {Form, FormGroup, Modal} from 'react-bootstrap';
import {Required} from './common';
import {FlashMessageContext} from '../context/flash-message';
import PropTypes from 'prop-types';


const HelpModal = (show, setShow) => {
  const flashMessageContext = useContext(FlashMessageContext);

  const defaultFormData = {
    name: '',
    email: '',
    topic: '',
    details: '',
  };
  const [formData, setFormData] = useState(defaultFormData);

  // called on each field change, updates the state data
  const updateForm = (name, value) => {
    setFormData((data) => ({...data, [name]: value}));
  };

  // called when the modal is closed, either by cancel or send
  // clears the form data and closes the modal
  const handleClose = () => {
    setFormData(defaultFormData);
    setShow(false);
  }

  const formattedMessage =  {
    "blocks": [
      {
        "type": "section",
        "text": {
          "type": "mrkdwn",
          "text": ` \
- *Name*: ${formData.name} \n \
- *Email*: <${formData.email}> \n \
- *Topic*: ${formData.topic} \n \
- *Details*: ${formData.details} \n`
        }
      }
    ]
  };

  // calls a slack webhook to send the message. If successful, shows a success message
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.topic || !formData.details) {
      flashMessageContext.showMessage('Please fill out all required fields.');
      return;
    }

    const webhookUrl = process.env.REACT_APP_SLACK_WEBHOOK_URL;
    fetch(webhookUrl, {
      mode: 'no-cors', // required for slack webhook
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedMessage),
    }).then(response => {
      if (!response.ok) {
        return {preflight: 'acceptable'};
      } else {
        return response.json();
      }
    }).then(data => {
      console.log('slack data', data);
    }).catch(error => {
      console.error(error);
    });
    flashMessageContext.showMessage('Message Sent!','success');
    handleClose();
  };

  return <>
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >

      < Modal.Header closeButton>
        < Modal.Title>Submit your inquiry or request< /Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formName'>
            <Form.Label><Required>Full Name</Required></Form.Label>
            <Form.Control
              type='text'
              placeholder='First Last'
              name='name'
              autoFocus={true}
              value={formData.name}
              onChange={(e) => updateForm(e.target.name, e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formEmail'>
            <Form.Label><Required>Email address</Required></Form.Label>
            <Form.Control
              type='email'
              name='email'
              placeholder='name@example.edu'
              value={formData.email}
              onChange={(e) => updateForm(e.target.name, e.target.value)}
            />
          </Form.Group>
          <FormGroup className='mb-3' controlId='formTopic'>
            <Form.Label><Required>Select a topic</Required></Form.Label>
            <Form.Select
              aria-label='Select Topic'
              name='topic'
              value={formData.topic || ''}
              onChange={(e) => updateForm(e.target.name, e.target.value)}
            >
              <option value=''>--</option>
              <option value='Login'>Logging In</option>
              <option value='Setup'>Setup / Configuration</option>
              <option value='Problem'>Bug, Issue, Problem</option>
              <option value='Admin'>Administration</option>
              <option value='Proctor'>Proctoring</option>
              <option value='Assessment'>Student Assessment</option>
              <option value='Other'>Other</option>
            </Form.Select>
          </FormGroup>
          <Form.Group className='mb-3' controlId='formDetails'>
            <Form.Label sm={1}>
              <Required>Description</Required>
            </Form.Label>
            <Form.Control
              as='textarea'
              placeholder='Please enter the details of your request here'
              rows={7}
              name='details'
              value={formData.details}
              onChange={(e) => updateForm(e.target.name, e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' name='cancelButton' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='primary' name="sendButton" onClick={sendMessage}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
};

export default HelpModal;

HelpModal.propTypes = {
  showHelp: PropTypes.bool,
};
