import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import stcHelpUri from '../help/HelpSchoolTestCoordinator.md';
import { useEffect, useState } from 'react';

export const HelpSchoolTestCoordinator = (props) => {
  const [stcHelpContent, setStcHelpContent] = useState('');
  useEffect(() => {
    fetch(stcHelpUri)
      .then((result) => {
        if (result) {
          return result.text();
        }
      })
      .then((data) => {
        if (data && !data.startsWith('<!DOCTYPE html>')) {
          setStcHelpContent(data);
        }
        return data;
      })
      .catch((error) => {
        console.error('Proctor Help response is null', error);
      });
  });

  return (
    <>
      <Container className='container-md'>
        <ReactMarkdown className={'bg-secondary-subtle p-5 border border-dark rounded-2'}>{stcHelpContent}</ReactMarkdown>
        <Form id={'nda-fields'} className={'col-sm-12 mt-4'}>
          <Row className='mb-3' key={'agree'}>
            <Form.Group as={Col} controlId='formSaveButton'>
              <Col className={'col-sm-2'}>
                <Button
                  variant='success'
                  name='saveButton'
                  size='lg'
                  onClick={() => window.location.href = '/'}
                  className='clickable'
                >Close</Button>
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </>
  );
};
