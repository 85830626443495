import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { FlashMessageContext } from '../context/flash-message';
import UserManagement from './UserManagement';
import StudentManagement, { pauseUnpauseMessageInfo } from './StudentManagement';
import api from '../api';
import '../styles/admin.css';
import { useCookies } from 'react-cookie';
import { renderSubheader } from './common';

const AdminPage = () => {
  const DEFAULT_TAB = 'users';
  const UNLOCKED_ASSESSMENT_STATUSES = ['running'];
  const { isAdmin, getTokenSilently, user, logout } = useAuth0();

  const [students, setStudents] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [assessmentId, setAssessmentId] = useState();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [assessmentLocked, setAssessmentLocked] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [assessmentStatusLoading, setAssessmentStatusLoading] = useState(false);
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [cookie, setCookie] = useCookies(['lastTab']);
  const [key, setKey] = useState('users');


  const history = useHistory();
  const flashMessage = useContext(FlashMessageContext);

  const loadStudents = async () => {
    setKey('students');
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    const firstSchool = schoolIds.split(',')[0];

    api.get(`/schools/${firstSchool}/latest-assessment`)
      .then((data) => {
        if (data && data.id) {
          setAssessmentId(data.id);
          if (UNLOCKED_ASSESSMENT_STATUSES.includes(data.status)) {
            setAssessmentLocked(false);
          } else {
            setAssessmentLocked(true);
          }
          api.get(`/assessments/${data.id}/students`)
            .then((data) => {
              if (data) {
                setStudents(data);
              } else {
                flashMessage.showMessage('No student data available.');
              }
            }).catch((error) => flashMessage.showMessage(error));
        } else {
          flashMessage.showMessage('No assessment data available yet.');
          logout();
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  const pauseOrUnpauseAssessment = async () => {
    const status = assessmentLocked ? 'running' : 'paused';

    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    const firstSchool = schoolIds.split(',')[0];

    api.get(`/schools/${firstSchool}/latest-assessment`)
      .then((data) => {
        if (data && data.id) {
          api.post(`/assessments/${data.id}/status`, { status })
            .then((data) => {
              if (data) {
                if (UNLOCKED_ASSESSMENT_STATUSES.includes(data.status)) {
                  setAssessmentLocked(false);
                } else {
                  setAssessmentLocked(true);
                }

                flashMessage.showMessage(
                  pauseUnpauseMessageInfo(assessmentLocked).flash_text,
                  pauseUnpauseMessageInfo(assessmentLocked).flash_variant,
                );
              }
            })
            .catch((error) => flashMessage.showMessage(error));
        }
      })
      .catch((error) => flashMessage.showMessage(error));
  };

  const addUser = (user) => {
    let updated;
    const existingIndex = adminUsers.findIndex((u) => u.user_id === user.user_id);
    if (existingIndex > -1) {
      updated = [
        ...adminUsers.slice(0, existingIndex),
        user,
        ...adminUsers.slice(existingIndex + 1),
      ];
    } else {
      updated = [...adminUsers, user];
    }

    setAdminUsers(updated);
  };

  const removeUser = (user) => {
    const existingIndex = adminUsers.findIndex((u) => u.user_id === user.user_id);
    const updated = [
      ...adminUsers.slice(0, existingIndex),
      ...adminUsers.slice(existingIndex + 1),
    ];
    setAdminUsers(updated);
  };

  const loadUsers = async () => {
    setKey('users');
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    setIsUserLoading(true);
    api.get('/admin/users', {
      'X-School-Ids': schoolIds,
      'X-Originator-Email': user.email,
    })
      .then((data) => {
        setAdminUsers(data);
        setIsUserLoading(false);
      })
      .catch((error) => {
        flashMessage.showMessage(error);
        setIsUserLoading(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const autoAssignExams = async () => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    api.post('/assign-exams', {}, {
      'X-School-Ids': schoolIds,
    })
      .then((result) => {
        if (result) {
          window.alert('Exams assigned.');
          switchTab('students');
        } else {
          flashMessage.showMessage('Auto-assign failed.');
        }
      })
      .catch((error) => {
        flashMessage.showMessage(`Auto-assign failed: ${error}`);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const closeAndScore = async () => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    api.post('/close-and-score', {}, {
      'X-School-Ids': schoolIds,
    })
      .then((result) => {
        if (result) {
          window.alert('Generating scores and associated reports.');
          flashMessage.showMessage('Reports being generated. Check back in a bit.');
          history.push('/reports');
        } else {
          flashMessage.showMessage('Scoring failed.');
        }
      })
      .catch((error) => {
        flashMessage.showMessage(`Scoring failed: ${error}`);
      });
  };

  const switchTab = (tab) => {
    loadTabData(tab);
  };

  const loadTabData = (tab) => {
    switch (tab) {
      case 'users':
        loadUsers();
        break;
      case 'students':
        loadStudents();
        break;
      default:
        setTab(DEFAULT_TAB);
        loadUsers();
        break;
    }
    setCookie('lastTab', tab);
  };

  useEffect(() => {
    const ensurePermissions = async () => {
      if (!isAdmin) {
        history.push('/');
      } else {
        const lastTab = cookie['lastTab'];
        if (lastTab) {
          loadTabData(lastTab);
        } else {
          loadTabData(tab);
        }
      }
    };

    ensurePermissions();
    // eslint-disable-next-line
  }, [isAdmin, history, tab]);

  return (
    <>
      {renderSubheader()}
      <section id="admin">
        <Tabs activeKey={key} onSelect={(tab) => switchTab(tab)} className="mb-3">
          <Tab title="Manage STCs and Proctors" eventKey="users" id="admin-stc">
            <UserManagement
              users={adminUsers}
              addUser={addUser}
              removeUser={removeUser}
              isLoading={isUserLoading}
              setIsLoading={setIsUserLoading}
              loadUsers={loadUsers}
            />
          </Tab>
          <Tab title="Manage Students" eventKey="students" id="admin-students">
            <StudentManagement
              assessmentId={assessmentId}
              students={students}
              setStudents={setStudents}
              assessmentPaused={assessmentLocked}
              pauseOrUnpauseAssessment={pauseOrUnpauseAssessment}
            />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default AdminPage;
